import axios from 'axios'
import { formatAllTeams } from './formatters'

const baseApi = axios.create({
    baseURL: 'https://running.gluki.io/api',
})

export async function getAllChallenges() {
    const endpoint = '/challanges'
    try {
        const { data } = await baseApi.get(endpoint)
        return data
    } catch (error) {
        console.log(Object.keys(error), error.message)
    }
}

export async function getAllTeamIndividuals(teamName) {
    try {
        const endpoint = `/teams/${teamName}`
        const { data } = await baseApi.get(endpoint)
        return data
    } catch (error) {
        console.log(Object.keys(error), error.message)
    }
}

export async function getAllTeamRuns(endpointData) {
    const { teamName } = endpointData
    const { startdate } = endpointData
    try {
        const endpoint = `/runsByTeam?startdate=${startdate}&teamName=${teamName}`
        const { data } = await baseApi.get(endpoint)
        return data
    } catch (error) {
        console.log(Object.keys(error), error.message)
    }
}

export async function getCharityChallengeScores() {
    const endpoint = 'challange_no_team?challangeId=1672531200'
    try {
        const { data } = await baseApi.get(endpoint)
        return data
    } catch (error) {
        console.log(Object.keys(error), error.message)
    }
}

export async function getTeamData(teamName, startdate) {
    const teams = await getAllTeamRuns({
        teamName,
        startdate,
    })
    const allTeamRunners = await getAllTeamIndividuals(teamName)

    return formatAllTeams(teams, allTeamRunners)
}
