import { useEffect, useState } from 'react'
import './styling/App.css'
import { convertChallengeDate } from './utils/utils'
import { getAllChallenges } from './utils/api'
import SideBar from './components/SideBar/SideBar'
import ChallengePage from './components/ChallengePage/ChallengePage'
import Homepage from './components/Homepage/Homepage'
import CharityChallengePage from './components/CharityChallengePage/CharityChallengePage'
import { orderChallenges } from './utils/utils'
import useScreenSize from './hooks/useScreenSize'
import MobileNav from './components/MobileNav/MobileNav'
import SideBarSkeleton from './components/SkeletonLoaders/SideBarSkeleton'

export interface ChallengeInterface {
    startdate: string
    enddate: string
    teams: string[]
}

export interface TotalsInterface {
    [index: string]: {
        totalDistance: number
        numberOfParticipants: number
    }
}

function App() {
    const { desktopView } = useScreenSize()
    const [viewBurgerMenu, setViewBurgerMenu] = useState(false)
    const [fetchedChallenges, setFetchedChallenges] = useState<
        ChallengeInterface[]
    >([
        {
            startdate: '',
            enddate: '',
            teams: [''],
        },
    ])
    const [totals, setTotals] = useState<TotalsInterface[]>(
        [] as TotalsInterface[]
    )

    useEffect(() => {
        getAllChallenges()
            .then((challenges) => {
                setFetchedChallenges(challenges)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const orderedChallenges = orderChallenges(fetchedChallenges)
    const challengeDates = orderedChallenges.map((challenge) => {
        const formattedDate = convertChallengeDate(challenge.startdate)
        return formattedDate === 'January 2023'
            ? 'Charity Challenge'
            : formattedDate
    })

    if (fetchedChallenges.length < 2) {
        return <SideBarSkeleton />
    }

    return (
        <div className="App">
            {desktopView && fetchedChallenges.length < 2 ? (
                <SideBarSkeleton />
            ) : desktopView ? (
                <SideBar challengeDates={challengeDates} />
            ) : (
                <MobileNav
                    challengeDates={challengeDates}
                    viewBurgerMenu={viewBurgerMenu}
                    setViewBurgerMenu={setViewBurgerMenu}
                />
            )}
            <main className={viewBurgerMenu ? 'blurred' : 'normal'}>
                <Homepage
                    totals={totals}
                    numberOfChallenges={fetchedChallenges.length}
                />
                {orderedChallenges.map((challenge, index) => {
                    return convertChallengeDate(challenge.startdate) ===
                        'January 2023' ? (
                        <CharityChallengePage
                            key={index}
                            setTotals={setTotals}
                        />
                    ) : (
                        <ChallengePage
                            key={index}
                            pageChallenge={challenge}
                            setTotals={setTotals}
                        />
                    )
                })}
            </main>
        </div>
    )
}

export default App
