import Leaderboard from '../Leaderboard/Leaderboard'
import '../Leaderboard/Leaderboard.css'
import './../../styling/App.css'
import ChallengeHeader from '../ChallengeHeader/ChallengeHeader'
import { useEffect, useState } from 'react'
import { getCharityChallengeScores } from '../../utils/api'
import SkeletonLoader from '../SkeletonLoaders/SkeletonLoader'
import { ScoresInterface } from '../ChallengePage/types'

export default function CharityChallengePage({ setTotals }: any) {
    const [charityScores, setCharityScores] = useState<ScoresInterface[]>([])
    const [totalDistance, setTotalDistance] = useState<number>(0)

    useEffect(() => {
        getCharityChallengeScores()
            .then(({ Runners }) => {
                for (const runnerName in Runners) {
                    if (runnerName === 'Total') {
                        const parsedDistance = parseInt(Runners.Total)
                        setTotalDistance(parsedDistance)
                    } else {
                        setCharityScores((currentScores) => {
                            return [
                                {
                                    name: runnerName,
                                    distance: parseInt(Runners[runnerName]),
                                },
                                ...currentScores,
                            ]
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        if (totalDistance !== 0) {
            setTotals((currTotals: any) => {
                return [
                    {
                        'Charity Challenge': {
                            totalDistance: totalDistance,
                            numberOfParticipants: charityScores.length,
                        },
                    },
                    ...currTotals,
                ]
            })
        }
        // eslint-disable-next-line
    }, [totalDistance, charityScores.length])

    return (
        <>
            {!charityScores.length ? (
                <SkeletonLoader id="charity-challenge" />
            ) : (
                <section className="Challenge_Page" id="charity-challenge">
                    <ChallengeHeader challengeDate={`Charity Challenge`} />
                    <section className="leaderboard">
                        <h2 className="table_header">Summary</h2>
                        <div className="table_wrapper">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="charity_total_distance_header">
                                            Total Distance (km)
                                        </td>
                                        <td className="charity_total_distance_score">
                                            {totalDistance} km
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <Leaderboard
                        mainHeader="All Runners"
                        scores={charityScores}
                    />
                </section>
            )}
        </>
    )
}
