import './MobileNav.css'
import Hamburger from 'hamburger-react'
import BurgerMenu from '../BurgerMenu/BurgerMenu'

export default function MobileNav({
    challengeDates,
    viewBurgerMenu,
    setViewBurgerMenu,
}) {
    return (
        <div className="MobileNav">
            <img
                className="GL_Logo_mobile"
                src="https://www.globallogic.com/wp-content/uploads/2021/07/Logo_GL-Hitachi_White-web.svg"
                width="150"
                alt="Global logic logo"
            ></img>
            <div
                onClick={() => {
                    setViewBurgerMenu(!viewBurgerMenu)
                }}
            >
                <Hamburger
                    toggled={viewBurgerMenu}
                    toggle={setViewBurgerMenu}
                />
            </div>
            {viewBurgerMenu ? (
                <BurgerMenu
                    challengeDates={challengeDates}
                    setViewBurgerMenu={setViewBurgerMenu}
                    viewBurgerMenu={viewBurgerMenu}
                />
            ) : null}
        </div>
    )
}
