export default function FloatingBoxes({ totals, numberOfChallenges }: any) {

    const isReadyToLoad = numberOfChallenges === totals.length;
    return (
        <div className="total_boxes">
            {isReadyToLoad &&
                totals.map((total: any, index: number) => {
                    if (index > 3) {
                        return null
                    }
                    const challengeName = Object.keys(total)[0]
                    return (
                        <div className="total_box" key={index}>
                            <div className="total_box_content">
                                <h2>{challengeName}</h2>
                                <p>
                                    Runners:{' '}
                                    {total[challengeName].numberOfParticipants}
                                </p>
                                <p>
                                    Distance:{' '}
                                    {Math.trunc(
                                        total[challengeName].totalDistance
                                    )}
                                    km
                                </p>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}
