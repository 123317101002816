import HeaderSkeleton from './HeaderSkeleton'
import LeaderboardSkeleton from './LeaderboardSkeleton'
import '../ChallengePage/ChallengePage.css'

export default function SkeletonLoader({ numberOfTables = 4, id }) {
    const skeletons = Array(numberOfTables).fill(null)

    return (
        <section className="Challenge_Page" id={id}>
            <HeaderSkeleton />
            {skeletons.map((_, index) => {
                return <LeaderboardSkeleton amount={4} key={index} />
            })}
        </section>
    )
}
