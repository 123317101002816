import './Homepage.css'
import './../../styling/App.css'
import { AiFillDownCircle } from 'react-icons/ai'
import useScreenSize from '../../hooks/useScreenSize'
import CarouselFloatingBoxes from '../CarouselFloatingBoxes/CarouselFloatingBoxes'
import FloatingBoxes from '../FloatingBoxes/FloatingBoxes'
import { ThreeDots } from 'react-loading-icons'
import { HomepagePropsInterface } from './types'

export default function Homepage({
    totals,
    numberOfChallenges,
}: HomepagePropsInterface) {
    const { screenWidth } = useScreenSize()

    return (
        <div id="Homepage">
            <section className="Homepage_header">
                <h1 className="main_header">
                    WELCOME TO GlobalLogic RUNNING PAGE
                </h1>
                {totals.length !== numberOfChallenges ? (
                    <div className="loading_msg_box">
                        {screenWidth > 520 ? (
                            <p className="loading">
                                Loading some impressive stats. . .
                            </p>
                        ) : (
                            <ThreeDots />
                        )}
                    </div>
                ) : screenWidth < 685 ? (
                    <CarouselFloatingBoxes totals={totals} />
                ) : (
                    <FloatingBoxes totals={totals} numberOfChallenges={numberOfChallenges}/>
                )}
                <h2 className="sub_header">
                    Scroll down to see up to date stats or navigate by challenge
                    name
                </h2>
                <div className="scroll_down_button">
                    <a href="#charity-challenge">
                        <AiFillDownCircle />
                    </a>
                </div>
            </section>
        </div>
    )
}
