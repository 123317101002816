import { convertToId } from '../../utils/utils'
import '../MobileNav/MobileNav.css'
export default function BurgerMenu({
    challengeDates,
    setViewBurgerMenu,
    viewBurgerMenu,
}: any) {
    return (
        <div className="burger_nav_active">
            <div className="burger_nav_links">
                {challengeDates.map((date: string, index: number) => {
                    return (
                        <a
                            className="mobile_nav_link"
                            key={index}
                            href={`#${convertToId(date)}`}
                            onClick={() => {
                                setViewBurgerMenu(!viewBurgerMenu)
                            }}
                        >
                            {date}
                        </a>
                    )
                })}
            </div>
        </div>
    )
}
