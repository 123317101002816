import { capActivitiesTo8km } from './utils'

const formatAllTeams = (
    teams: { activities: unknown; teamName: unknown },
    individuals: unknown
) => {
    if (!teams) {
        return {
            teamName: 'Uknown',
            cappedActivities: [
                {
                    activity: 'run',
                    ts: '',
                    elevation: '',
                    runnerId: '',
                    distance: 0,
                },
            ],
            allTeamRunners: [{ id: '', name: 'Unknown' }],
        }
    }
    const { activities, teamName } = teams

    const cappedActivities = capActivitiesTo8km(activities)
    return {
        teamName,
        cappedActivities,
        allTeamRunners: individuals,
    }
}

export { formatAllTeams }
