import { useState, useEffect, useCallback, useMemo } from 'react'
import {
    convertChallengeDate,
    convertToId,
    updateFloatingBoxesTotals,
} from '../../utils/utils'
import { getTeamData } from '../../utils/api'
import SkeletonLoader from '../SkeletonLoaders/SkeletonLoader'
import './../../styling/App.css'
import ChallengeHeader from '../ChallengeHeader/ChallengeHeader'
import './ChallengePage.css'
import Leaderboard from '../Leaderboard/Leaderboard'
import {
    ScoresInterface,
    IndividualScoresInterface,
    ChallengePageProps,
} from './types'
import { calculateCurrentTeamScores } from './utils'

export default function ChallengePage({
    pageChallenge,
    setTotals,
}: ChallengePageProps) {
    //declare required states
    const [scores, setScores] = useState<any>({
        individual: [] as IndividualScoresInterface[],
        teams: [] as ScoresInterface[],
    })

    const [floatingBoxesTotals, setFloatingBoxesTotals] = useState({
        numberOfTeamsChecked: 0,
        totalNumberOfParticipants: 0,
        totalChallengeDistance: 0,
    })

    //declare required varibales
    const [pageChallengeId, challengeDate] = useMemo(() => {
        const pageChallengeId = convertToId(
            convertChallengeDate(pageChallenge?.startdate)
        )
        const challengeDate = convertChallengeDate(pageChallenge?.startdate)
        return [pageChallengeId, challengeDate]
    }, [pageChallenge?.startdate])

    //helper functions for dealing with data for each/current team
    const processCurrentTeamData = useCallback(
        async (team: string) => {
            const { teamName, cappedActivities, allTeamRunners } =
                await getTeamData(team, pageChallenge.startdate)
            const {
                currentTeamIndividualScores,
                currentTeamTotalScore,
                totalDistanceForEachIndividual,
                totalDistanceForTheTeam,
            } = calculateCurrentTeamScores(
                cappedActivities,
                teamName,
                allTeamRunners
            )

            setScores((prevState: any) => ({
                individual: [
                    currentTeamIndividualScores,
                    ...prevState.individual,
                ],
                teams: [currentTeamTotalScore, ...prevState.teams],
            }))
            setFloatingBoxesTotals((currTotals) => {
                return updateFloatingBoxesTotals(
                    currTotals,
                    totalDistanceForEachIndividual,
                    totalDistanceForTheTeam
                )
            })
        },
        [pageChallenge.startdate]
    )

    const fetchAndProcessCurrentTeamData = useCallback(
        async (team: string) => {
            try {
                processCurrentTeamData(team)
            } catch (error) {
                console.log(error)
            }
        },
        [processCurrentTeamData]
    )

    // interates over all teams in the challenge and triggrs helper functions for each team
    useEffect(() => {
        const fetchDataForEachTeam = async () => {
            const teamPromises = (pageChallenge.teams ?? []).map(
                fetchAndProcessCurrentTeamData
            )
            await Promise.all(teamPromises)
        }

        fetchDataForEachTeam()

        return () => {
            // Cancel pending requests here
        }
    }, [fetchAndProcessCurrentTeamData, pageChallenge.teams])

    // updates floating boxes state passed down from the parent
    useEffect(() => {
        if (
            floatingBoxesTotals.numberOfTeamsChecked ===
            pageChallenge.teams.length
        ) {
            setTotals((prevTotals: any) => [
                {
                    [challengeDate]: {
                        totalDistance:
                            floatingBoxesTotals.totalChallengeDistance,
                        numberOfParticipants:
                            floatingBoxesTotals.totalNumberOfParticipants,
                    },
                },
                ...prevTotals,
            ])
        }
        // eslint-disable-next-line
    }, [floatingBoxesTotals])

    return (
        <>
            {!scores.individual.length || !scores.teams.length ? (
                <SkeletonLoader id={`${pageChallengeId}`} />
            ) : (
                <section className="Challenge_Page" id={`${pageChallengeId}`}>
                    <ChallengeHeader challengeDate={challengeDate} />
                    <Leaderboard mainHeader="All Teams" scores={scores.teams} />
                    <>
                        {scores.individual.map((team: any, index: number) => {
                            return (
                                <Leaderboard
                                    key={index}
                                    mainHeader={`${team.name}`}
                                    scores={team.individualScores}
                                />
                            )
                        })}
                    </>
                </section>
            )}
        </>
    )
}
