import "../SideBar/SideBar.css";
import Skeleton from "react-loading-skeleton";

export default function SideBarSkeleton() {
    const skeletonArray = Array(4).fill(1)
  return (
    <div id='SideBar'>
      <img
        className='GL_Logo'
        src='https://www.globallogic.com/wp-content/uploads/2021/07/Logo_GL-Hitachi_White-web.svg'
        width='190'
        alt='Globallogic logo'
      ></img>
      <div className='skeleton_links'>
        {skeletonArray.map((_, index) => {
          return (
            <div className="skeleton_link" key={index}>
            <Skeleton width={150} height={35} key={_}/>
            </div>
          );
        })}
      </div>
    </div>
  );
}