import {
    calculateTeamTotalDistance,
    calculateIndividualDistance,
} from '../../utils/utils'

const calculateCurrentTeamScores = (
    cappedActivities: any,
    teamName: any,
    allTeamRunners: any
) => {
    const totalDistanceForEachIndividual = calculateIndividualDistance(
        cappedActivities,
        allTeamRunners,
        true
    )
    const totalDistanceForTheTeam = calculateTeamTotalDistance(
        totalDistanceForEachIndividual,
        teamName
    )
    return {
        currentTeamIndividualScores: {
            name: teamName,
            individualScores: totalDistanceForEachIndividual,
        },
        currentTeamTotalScore: {
            name: teamName,
            distance: totalDistanceForTheTeam.distance,
        },
        totalDistanceForEachIndividual,
        totalDistanceForTheTeam,
    }
}

export { calculateCurrentTeamScores }
