import './Leaderboard.css'
import { sortByDescendingOrder, convertName } from '../../utils/utils'
import useScreenSize from '../../hooks/useScreenSize'
import { LeaderboardPropsInterface, Score } from './types'

function generateTableHeaders(
    mainHeader: string,
    desktopView: boolean,
    mobileView: boolean
): string[] {
    if (mainHeader === 'All Teams') {
        return desktopView
            ? ['Position', 'Name', 'Total Distance']
            : ['Position', 'Name', 'Total(km)']
    }

    if (mainHeader === 'All Runners') {
        return desktopView ? ['Name', 'Total Distance'] : ['Name', 'Total(km)']
    }

    return mobileView
        ? ['Name', 'Total(km)', 'Runs']
        : ['Name', 'Total Distance', 'Number of Runs']
}

function generateTableRows(
    mainHeader: string,
    index: number,
    score: Score,
    mobileView: boolean
): JSX.Element[] {
    const name = convertName(score.name)
    const distance = `${score.distance} ${!mobileView ? 'km' : ''}`
    const numberOfRuns = !score.numberOfRuns ? 0 : score.numberOfRuns

    if (mainHeader === 'All Teams') {
        return [
            <th className="position" scope="row">
                {index + 1}
            </th>,
            <th>{name}</th>,
            <td>{distance}</td>,
        ]
    }

    if (mainHeader === 'All Runners') {
        return [<th>{name}</th>, <td>{distance}</td>]
    }

    return [<th>{name}</th>, <td>{distance}</td>, <td>{numberOfRuns}</td>]
}

export default function Leaderboard({
    mainHeader,
    scores,
}: LeaderboardPropsInterface) {
    const { desktopView, mobileView } = useScreenSize()
    const tableHeaders = generateTableHeaders(
        mainHeader,
        desktopView,
        mobileView
    )

    const sortedScores =
        mainHeader === 'All Teams'
            ? sortByDescendingOrder(scores, 'distance')
            : sortByDescendingOrder(scores, 'name')

    return (
        <section className="leaderboard">
            <h2 className="table_header">{convertName(mainHeader)}</h2>
            <div className="table_wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            {tableHeaders.map((header, index) => (
                                <th
                                    key={index}
                                    className={
                                        header === 'Total(km)'
                                            ? 'Total Distance'
                                            : header
                                    }
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedScores.map((score, index) => (
                            <tr key={index}>
                                {generateTableRows(
                                    mainHeader,
                                    index,
                                    score,
                                    mobileView
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    )
}
