import Carousel from 'react-bootstrap/Carousel'
import { useState } from 'react'

export default function CarouselFloatingBoxes({ totals }: any) {
    const [index, setIndex] = useState(0)

    const handleSelect = (selectedIndex: any, e: any) => {
        setIndex(selectedIndex)
    }

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {totals?.map((total: any, index: number) => {
                const challengeName = Object.keys(total)[0]
                return (
                    <Carousel.Item interval={2300} key={index}>
                        <div className="carousel_total_box">
                            <div className="carousel_total_box_content">
                                <h2>{challengeName}</h2>
                                <p>
                                    Runners:{' '}
                                    {total[challengeName].numberOfParticipants}
                                </p>
                                <p>
                                    Distance:{' '}
                                    {total[challengeName].totalDistance}km
                                </p>
                            </div>
                        </div>
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}
