import { useState, useEffect } from 'react'

export default function useScreenSize() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const mobileView = screenWidth < 1100
    const desktopView = screenWidth >= 1100

    return { screenWidth, mobileView, desktopView }
}
