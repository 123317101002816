import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../Leaderboard/Leaderboard.css"
import useScreenSize from '../../hooks/useScreenSize'

export default function LeaderboardSkeleton({ amount }: {amount: number}) {
  const { mobileView } = useScreenSize();
  const tableHeaders = mobileView ? ["Position", "Name", "Total(km)"] : ["Position", "Name", "Total Distance"]
  const loadTables = Array(amount).fill(1);

  return (
    <section className='leaderboard'>
      <h2 className='table_header'><Skeleton width={500} height={35}/></h2>
      <div className='table_wrapper'>
        <table className='table table-borderless'>
        <thead>
            <tr>
              {tableHeaders.map((header, index) => (
                 <th key={index} className={`${header}`} >{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loadTables.map((_, index) => {
              return (
                <tr key={index}>
                  <th scope='row'>
                    <Skeleton height={18}/>
                  </th>
                  <td>
                    <Skeleton height={18}/>
                  </td>
                  <td>
                    <Skeleton height={18} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
}
