import './SideBar.css'
import { convertToId } from '../../utils/utils'

export default function SideBar({ challengeDates }: any) {
    return (
        <div id="SideBar" className="list-group">
            <a href="#Homepage">
                <img
                    className="GL_Logo"
                    src="https://www.globallogic.com/wp-content/uploads/2021/07/Logo_GL-Hitachi_White-web.svg"
                    width="190"
                    alt="Global logic logo"
                ></img>
            </a>
            <div className="links">
                {challengeDates.map((date: string, index: number) => {
                    return (
                        <a
                            className="list-group-item list-group-item-action"
                            id={`SideBar_link`}
                            key={index}
                            href={`#${convertToId(date)}`}
                        >
                            {date}
                        </a>
                    )
                })}
            </div>
        </div>
    )
}
