const emoji = require('emoji-dictionary')

export interface ChallengeInterface {
    startdate: string
    enddate: string
    teams: string[]
}

export interface ParsedChallengeInterface {
    startdate: string
    enddate: string
    teams: string[]
    challengeName: string
}

interface RunningActivitiesInterface {
    activity: string
    distance: string
    elevation: string
    runnerName: string
    ts: string
}

interface totalDistanceForEachIndividualInterface {
    name: string
    distance: number
    numberOfRuns: number
}

export const convertChallengeDate = (unixTimestamp: string): string => {
    let unixTimestampToNumber: number = parseInt(unixTimestamp)
    let date: Date = new Date(unixTimestampToNumber * 1000)
    return date.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
    })
}

export const sortByDescendingOrder = (data: any[], sortValue: string) => {
    if (!sortValue) {
        throw new Error('You need to pass data and sort value!')
    } else if (!data[0].hasOwnProperty(sortValue)) {
        throw new Error('Invalid sort value!')
    }

    return data.sort(
        (valueA, valueB) =>
            parseInt(valueB[sortValue]) - parseInt(valueA[sortValue])
    )
}

export const sortByAscendingOrder = (data: any[], sortValue: string) => {
    if (!sortValue) {
        throw new Error('You need to pass data and sort value!')
    } else if (!data[0].hasOwnProperty(sortValue)) {
        throw new Error('Invalid sort value!')
    }
    return data.sort(
        (valueA, valueB) =>
            parseInt(valueA[sortValue]) - parseInt(valueB[sortValue])
    )
}

export const calculateTeamTotalDistance = (
    fetchedTeamRuns: totalDistanceForEachIndividualInterface[],
    teamName: string
) => {
    if (!teamName) {
        throw new Error('You need to pass a team name!')
    } else if (!fetchedTeamRuns) {
        throw new Error('The passed data must contain a `distance` key')
    }
    const allRuns: number[] = []

    fetchedTeamRuns?.forEach((run: any) => {
        const { distance } = run
        allRuns.push(parseFloat(distance))
    })
    const totalDistance = allRuns.reduce(
        (runDistanceA, runDistanceB) => runDistanceA + runDistanceB,
        0
    )

    return { name: teamName, distance: parseFloat(totalDistance.toFixed(2)) }
}

interface RunnerResult {
    name: string
    distance: number
    numberOfRuns: number
}

interface TotalDistance {
    name: string
    distance: number
    numberOfRuns: number
}

function getRunnersWithNoScores(
    allRunnersData: RunnerResult[],
    totalDistanceForEachIndividual: TotalDistance[]
): TotalDistance[] {
    const namesOfRunnersWithScores = totalDistanceForEachIndividual.map(
        ({ name }) => name
    )
    const noScoreRunners = allRunnersData.filter(
        ({ name }) => !namesOfRunnersWithScores.includes(name)
    )

    return noScoreRunners.map(({ name }) => ({
        name,
        distance: 0,
        numberOfRuns: 0,
    }))
}

export const calculateIndividualDistance = (
    teamActivities: RunningActivitiesInterface[],
    allTeamRunners: any,
    capped: boolean
): TotalDistance[] => {
    const resultsHolder: Record<
        string,
        { name: string; distance: number; numberOfRuns: number }
    > = {}

    for (const { runnerName, distance, activity } of teamActivities) {
        const result = resultsHolder[runnerName]
        const canAddToResults =
            !capped ||
            (activity === 'run' && (!result || result.numberOfRuns < 8))

        if (canAddToResults) {
            const parsedDistance = parseFloat(distance)
            if (!result) {
                resultsHolder[runnerName] = {
                    name: runnerName,
                    distance: parsedDistance,
                    numberOfRuns: 1,
                }
            } else {
                result.distance += parsedDistance
                result.numberOfRuns++
            }
        }
    }

    const totalDistanceForEachIndividual = Object.values(resultsHolder).map(
        ({ name, distance, numberOfRuns }) => ({
            name,
            distance: parseFloat(distance.toFixed(2)),
            numberOfRuns,
        })
    )

    const runnersWithNoScores = getRunnersWithNoScores(
        allTeamRunners,
        totalDistanceForEachIndividual
    )

    return [...totalDistanceForEachIndividual, ...runnersWithNoScores]
}

export const convertName = (name: string): string => {
    if (name.split(':')[1]) {
        const defaultEmoji = emoji.getUnicode('100')

        const teamName = name.split(' ')
        const parsedTeamName = teamName.map((teamNameWord) => {
            return teamNameWord[0] === ':' &&
                emoji.getUnicode(teamNameWord.split(':')[1]) !== undefined
                ? emoji.getUnicode(teamNameWord.split(':')[1])
                : teamNameWord[0] === ':' &&
                  emoji.getUnicode(teamNameWord.split(':')[1]) === undefined
                ? defaultEmoji
                : teamNameWord
        })
        return parsedTeamName.join(' ')
    } else {
        const splitName = name.split('.')
        const result = splitName.map((split) => {
            return split.charAt(0).toUpperCase() + split.slice(1)
        })

        return result.join(' ')
    }
}

export const orderChallenges = (challenges: ChallengeInterface[]) => {
    const orderedChallenges = sortByDescendingOrder(challenges, 'startdate')

    const charityChallengeIndex = challenges.findIndex(
        ({ startdate }) => convertChallengeDate(startdate) === 'January 2023'
    )
    if (charityChallengeIndex !== -1) {
        const charityChallenge = orderedChallenges.splice(
            charityChallengeIndex,
            1
        )
        orderedChallenges.splice(0, 0, ...charityChallenge)
    }
    return orderedChallenges
}

export const convertToId = (id: string) => {
    const phasedId = id.split(' ')
    const result = phasedId.map((word: string) => word.toLowerCase())
    return result.join('-')
}

export const capActivitiesTo8km = (activities: any) => {
    return activities.map((activity: any) => {
        return {
            ...activity,
            distance: Math.min(activity.distance, 8),
        }
    })
}

export const updateFloatingBoxesTotals = (
    currNumber: any,
    totalDistanceForEachIndividual: any,
    totalDistanceForTheTeam: any
) => ({
    numberOfTeamsChecked: currNumber.numberOfTeamsChecked + 1,
    totalNumberOfParticipants:
        currNumber.totalNumberOfParticipants +
        totalDistanceForEachIndividual.length,
    totalChallengeDistance:
        currNumber.totalChallengeDistance + totalDistanceForTheTeam.distance,
})
